body {
  overscroll-behavior: none;
  overflow-x: hidden;
  font-family: poppins;
  font-size: 0.9rem;
}

.main {
  height:  100vh;
  width:  100vw;
  display: flex;
  flex-flow: column;
  overflow: hidden;
}

.main .nav-container {
  z-index: 100;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 45px;
  height: 100%;
  width: 100%;
}

.content {
  flex-grow:  1;
  flex-shrink: 1;
  flex-basis: auto;
  overflow-y:  auto;
  overflow-x: hidden;
}

.left {
  width:  100%;
  height:  100%;
  background: limegreen;
}

.middle {
  width:  100%;
  height:  100%;
  background: paleturquoise;
}

.right {
  width:  100%;
  height:  100%;
  background: orangered;
}

.divider {
  display: inline-block;
  height:  86px;
  top:  100px;
  width:  4px;
  background-color: #6187EB;
  cursor: col-resize;
  z-index: 2;
}

.panel-container {
  height:  100%;
  width:  100%;
  position: relative;
  box-sizing: border-box;
}

.panel-container .divider:nth-of-type(4) {
  top:  440px;
}

.panel {
  box-sizing: border-box;
  z-index: 2;
  overflow-y: scroll;
}

.panel-container .panel:nth-of-type(3) {
  z-index: 1;
}

.map-hole {
  height: 100%;
}

.map-container {
  position: fixed;
  left:  0;
  top:  0;
  width: 100vw;
}

.mapboxgl-ctrl-attrib-inner {
  z-index: -1;
}