.code-snippet pre {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  background: #292D36;
  border: 1px solid #31353E;
  border-left: 3px solid #7A63F2;
  line-height: 1.6;
  margin-bottom: 1.6em;
  margin-right: 10px;
  max-width: 100%;
  padding-top: 10px;
  padding-right: 12px;
  padding-bottom: 10px;
  padding-left: 12px;
  color: rgb(152, 152, 152);
  /*color: #666;*/
  page-break-inside: avoid;
  font-family: "Menlo", consolas, monospace;
}

.code-snippet code {
  float: left;
  width: calc(100% - 20px);
  overflow-x: scroll;
  overflow-y: scroll;
  word-wrap: break-word;
}

.code-snippet icon-container {
  display: inline-block;
  width:  20px;
}

.code-snippet button {
  color: #777E8B;
  background: none;
  border-style: none;
  padding: 10px;
  width: 14px;
  height: 16px;
  position: relative;
  cursor: pointer;
  top: -4px;
  left: -5px;
}

.code-snippet button:hover {
  color: #AFB4BE;
}

.code-snippet button:active {
  color: #cad1de;
}
