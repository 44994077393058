.navbar {
  color: black;
  font-size: 18px;
  padding-top: 8px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 0px;
  height: 100%;
  background-color: #31353E;
}

.navbar img {
  width:  25px;
  height:  25px;
  margin-right: 12px;
  position: relative;
  top: 7px;
}

.navbar .title-container {
  display: inline-block;
}

.navbar .actions-container {
  float:  right;
  display: inline-block;
  padding-top: 6px;
}

.navbar .title-container a {
  text-decoration: none;
  font-family: poppins;
  color:  white;
  vertical-align: middle;
  text-transform: uppercase;
}

.navbar .actions-container button {
  background:  none;
  border: none;
  padding: 0;
  margin-left: 20px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  color: #777E8B;
  font-weight: 100;
}
