#sideMenu {
  color: rgb(176, 180, 190);
  padding:  10px;
  background-color: #31353E;
  /*border-width: 4px;*/
  /*border-style: solid;*/
  width: 400px;
  position: fixed;
  right: 0px;
  top: 45px; /* This should match the height of the top menu bar */
  height: calc(100vh - 58px);
  z-index: 999;
  transition: transform .4s
              cubic-bezier(0, .52, 0, 1);
  overflow-y: scroll;
}

#sideMenu.hide {
  transform: translate3d(450px, 0, 0);
}

#sideMenu.show {
  transform: translate3d(0, 0, 0);
}

#sideMenu button {
  float: right;
  font-size: 20px;
  background:  none;
  border: none;
  padding: 0;
  margin-right: 10px;
  cursor: pointer;
  outline: inherit;
  color: #777E8B;
  font-weight: 100;
}

#sideMenu ul {
  list-style-type: none;
  padding:  0;
  margin:  0;
}

#sideMenu li {
  padding-top:  7px;
  padding-bottom:  7px;
  font-size: 1rem;
}

#sideMenu li.section {
  color:  white;
  font-size: 1.3rem;
  font-weight: 500;
  padding-left:  10px;
}

#sideMenu li a {
  text-decoration: none;
  color: #777E8B;
  display: block;
  width:  100%;
  height:  100%;
  padding-left:  10px;
}

#sideMenu li a.disabled {
  color: #806e6e;
  cursor: default;
}
